.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
form{
  width: 100%;
  margin: 0 auto 0 auto;
}
form input{
  width: 50%;
  margin: 0 auto 0 auto;
  height: 35px;
  display: block;
  margin-bottom: 15px;
  padding-left: 10px;
  border-radius: 5px;
  border: #a8a8a8 solid 2px;

}
#home > img{
  margin-top: 20px;
}
#welcomep{
font-size:15px;
text-align: center;
color:#5f5f5f;
}
#send{
  background-color: black;
  color: white;
  width: 30%;
  height: 45px;
  margin-top: 30px;
  border: black solid 2px;
  cursor: pointer;
}
.show-menu{
  width: 50%;
  height: 100%;
  position: fixed;
  top:70px;
  left: 0px;
  transition: 1s;
  background-color: white;
  }
  #menu{
  position:absolute;
  left:10px;
  top:8px;
  }
  .hide-menu{
  width: 50%;
  height: 100vh;
  position: fixed;
  top:70px;
  left: -376px;
  transition: 1s;
  background-color: white;
  }
#MfooterRight img{
  margin-top: 30px;
  margin-left: 10px;
}
#MBody > #home >img{
  margin-top: 40px;
}
#Mheader{
  width: 100%;
  background-color: white;
  height: 70px;
  
}
#Mheader p{
  margin-top: 0;
  margin-bottom: 0;
  line-height: 50px;
  color: #5f5f5f;
}
#MBody{
  width: 100%;
}
#MFooter{
  width:80%;
}
#Mlogo{
  width:100%;
  margin-top: 10px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
#Mtab{
  width: 100%;
  height: 50px;
  float: right;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
#MBody img{
  width: 100%;
}
#Mtab p{
  margin-left:30px;
  font-size: 18px;
  text-align: left;
}
header{
  width: 70%;
  height: 60px;
  line-height: 40px;
  margin : 0 auto 0 auto;
  left:0;
  right:0;
  position: fixed;
}
#logo{
  width:20%;
  float: left;
  height: 50px;
  cursor:pointer;
}
#tab{
  float:left;
  width:80%;
  height: 50px;
}
#tab p {
  float: right;
  margin-left: 2%;
  cursor: pointer;
  color:#5f5f5f;
}
#tab p:hover{
  color:#b39283;
}
hr{
  width:30px;
  margin: 15px auto 30px auto;
}
body > div >p{
  font-size: 25px;
}
.subTitle{
  padding-top: 80px;
  margin-top: 0;
  margin-bottom: 0;
}
body{
  width: 100%;
}
#googlemap{
  border: none;
}
#footerLeft{
  width:60%;
  float: left;
}
#footerLeft > p:nth-last-child(1){
  margin-bottom: 100px;
  color:#5f5f5f;
}
#footerLeft > p:not(#footerlogo,:nth-last-child(1)){
  margin-top: 0px;
  margin-bottom: 5px;
  color:#5f5f5f;
}
#footerlogo{
  margin-bottom: 30px;
  font-size: 25px;
}
#footerRight{
  width:40%;
  float: right;
}
#footerRight img{
  float: right;
  margin-top: 30px;
  margin-right:15px ;
  cursor:pointer;
}
footer{
  width:70%;
  margin:30px auto 0 auto;
 
}